<template>
<div class="Mengment">
  <el-container>
    <el-aside width="200px">
      <el-menu router  :default-openeds="['0']">
          <el-submenu  v-for="(item,index) in $router.options.routes" v-if="item.meta.menuShow" :key="item.path" :index="index+''">
            <template slot="title"><i class="el-icon-setting">{{item.meta.menuName}}</i></template>
                <el-menu-item v-for="(irem2, index2) in item.children" v-if="irem2.meta.menuShow" :class="irem2.path == $route.path?'is-active':''" :key="irem2.path" :index="irem2.path">{{irem2.meta.menuName}}</el-menu-item>
          </el-submenu>
      </el-menu>
    </el-aside>
    <el-container>
      <el-header style="text-align: right; font-size: 12px">
        <el-dropdown>
          <i class="el-icon-setting" style="margin-right: 15px"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>查看</el-dropdown-item>
            <el-dropdown-item>新增</el-dropdown-item>
            <el-dropdown-item>删除</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <span>王小虎</span>
      </el-header>

      <el-main>
        <router-view></router-view>
      </el-main>
      <el-footer>Footer</el-footer>
    </el-container>
  </el-container>

</div>
</template>

<script>
import ArticleText from "@/components/ArticleText";
import About from "@/components/About";
export default {

  name: "Mengement",

 components:{
    'v-ArticleText':ArticleText,
   'v-About':About
  },
  data(){
    const item = {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        };
        return {
        tableData: Array(20).fill(item),
          count:1
    }
  },
  methods:{
    test:function (){
      this.count=2
    },
    tesst:function (){
      this.count=1
    }
  }
}

</script>

<style scoped>

</style>